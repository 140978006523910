@import 'variables';

body {
  background: $black;
  color: $white;
}

.container {
  height: 100%;
  margin: 0 auto;
  max-width: 1600px;
  position: relative;
  width: 100%;
}

.two-column {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: $small-breakpoint) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.button--download {
  background: $red;
  border: 0;
  color: $white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transform-origin: center center;
}

.bold {
  font-weight: bold;
}

.no-break {
  word-spacing:normal;
}