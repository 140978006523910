@import '../variables';

.footer {
  padding: $padding;

  @media screen and (max-width: $small-breakpoint) {
    padding: calc($padding/2) calc($padding/2) $padding;
  }

  .container {
    display: flex;
    justify-content: flex-end;
    padding: 0 $padding;

    @media screen and (max-width: $small-breakpoint) {
      align-items: center;
      flex-direction: column;
      gap: calc($padding/3);
      justify-content: center;
      padding: 0;
    }
  }

  a {
    padding: calc($padding/3) 0;
    position: relative;
    transition: color 200ms linear;

    &:after {
      content: '';
      background: $white;
      bottom: 0;
      height: 1px;
      left: 0;
      position: absolute;
      transform: translateY(-16px);
      transition: background 200ms linear, transform 200ms ease-out;
      width: 100%;
    }

    &:hover {
      color:$red;

      &:after {
        background: $red;
        transform: translateY(-19px)
      }
    }
  }
}

.footer__logo {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: $small-breakpoint) {
    left: 0;
    position: relative;
    top: 0;
    transform: none;
  }
}