@import 'variables';

H1 {
  color: $white;
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  text-transform: uppercase;

  @media screen and (max-width: $small-breakpoint) {
    font-size: 50px;
    line-height: 50px;
  }
}

H2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  @media screen and (max-width: $small-breakpoint) {
    font-size: 16px;
    line-height: 24px;
  }
}

.subhead {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: calc($padding/3);
}

.copyright {
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
}

a {
  color: $white;
  position: relative;
  text-decoration: none;
}

ul {
  line-height: 1.6em;
  list-style: none;
  margin: 0;
  padding: 0;
}