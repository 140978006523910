@import '../variables';

.module__current-issue {
  background: $white;
  color: $black;
}

.module__current-issue--cover {
  cursor: pointer;
  position: relative;
  width: 100%;

  @media screen and (max-width: $small-breakpoint) {
    width: 100%;
  }

  img {
    filter: grayscale(100%);
    transition: filter 200ms linear;
    width: 100%;
  }

  &:hover {
    img {
      filter: grayscale(0%);
    }
  }

  .button--download {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(50%) rotate(-90deg);

    @media screen and (max-width: $small-breakpoint) {
      bottom: 0;
      left: 50%;
      right: auto;
      top: auto;
      transform: translateY(50%) translateX(-50%) rotate(0);
    }
  }
}

.current-issue-link {
  width: 50%;

  @media screen and (max-width: $small-breakpoint) {
    width: 100%;
  }
}

.module__current-issue--details {
  padding: $padding;
  text-align: right;
  width: 50%;

  @media screen and (max-width: $small-breakpoint) {
    padding: $padding calc($padding/2);
    width: 100%;
  }

  h2 {
    margin-bottom: calc($padding/2);
    word-spacing: 100vw;
  }
}