@import '../variables.scss';

.module__hero {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  h1 {
    line-height: 60px;
    transform: translate(-5px, 2px);

    @media screen and (max-width: $small-breakpoint) {
      line-height: 40px;
      transform: translate(-5px, calc($padding/2));
    }
  }

  h2 {
    color: $red;
    cursor: pointer;
  }
}

.module__hero--headline {
  bottom: 0;
  left: 0;
  position: absolute;
  word-spacing: 100vw;

  @media screen and (max-width: $small-breakpoint) {
    bottom: auto;
    top: 0;
  }
}

.module__hero--details {
  bottom: 0;
  max-width: 520px;
  right: 0;
  padding: $padding;
  position: absolute;

  @media screen and (max-width: $small-breakpoint) {
    max-width: 360px;
    padding: calc($padding/2);
  }

  h2, p {
    text-align: right;
  }
}

.module__hero--animation {
  align-items: center;;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  z-index: 0;
}

.mdlab_logo--static {
  opacity: 0.1;
  transition: opacity 200ms linear;
}

.module__hero--animation svg {
  cursor: pointer;

  &:hover {
    .mdlab_logo--static {
      opacity: 0.5;
    }
  }
}

#mdlab_arrow_1, #mdlab_arrow_2 {
  animation: hero 2s infinite;
  animation-timing-function: ease-out;
}

#mdlab_arrow_2 {
  animation-delay: 1s;
}

@keyframes hero {
  0% {
    opacity: 0;
    transform: translate(527, 278);
  }
  50% {
    opacity: 0.05;
  }
  80% {
    transform: translate(527, 378);
  }
  100% {
    opacity: 0;
    transform: translate(527, 378);
  }
}

