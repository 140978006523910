@import '../variables';

.module__past-issues {
  padding: $padding;

  @media screen and (max-width: $small-breakpoint) {
    padding: calc($padding/2);
  }

  h1 {
    margin-bottom: $padding;
    padding-right: $padding;
    text-align: right;
    word-spacing: 100vw;

    @media screen and (max-width: $small-breakpoint){
      padding-right: 0;
    }
  }
}

.module__past-issues--list {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.module__past-issues--item {
  margin-bottom: calc($padding/2);
  position: relative;

  .button--download {
    left: 0;
    opacity: 0;
    position: absolute;
    transform: translateY(-50%) translateX(-45%) rotate(-90deg);
    transform-origin: center center;
    transition: opacity 200ms linear, transform 200ms linear;
    top: 50%;
    z-index: 20;
  }

  &:hover {
    .button--download {
      opacity: 1;
      transform: translateY(-50%) translateX(-50%) rotate(-90deg);
    }

    .module__past-issues--issue {
      &:after {
        opacity: 0.3;
      }
      img {
        filter: grayscale(0%);
      }
    }
  }
}

.module__past-issues--issue {
  align-items: center;
  border: 4px solid $white;
  cursor: pointer;
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    background: $black;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 200ms linear;
    width: 100%;
    z-index: 1;
  }

  img {
    filter: grayscale(100%);
    height: auto;
    object-fit: cover;
    object-position: center;
    transition: filter 200ms linear;
    width: 100%;

    @media screen and (max-width: $small-breakpoint) {
      transform: scale(1.2);
    }
  }

  h2 {
    bottom: 0;
    padding: calc($padding/6);
    position: absolute;
    right: 0;
    text-align: right;
    word-spacing: 800px;
    z-index: 10;

    @media screen and (max-width: $small-breakpoint) {
      font-size: 30px;
      line-height: 30px;
    }
  }
}